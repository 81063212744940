<template>
    <div class="vi-change-subjects-or-bundle">
        <h5 class="fw-bold text-white billing-heading">Manage Subjects and Bundle</h5>

        <template v-if="initializing === false">
            <template v-if="!isDisabled">
                <div class="info mb-3">
                    <div class="info-bundle">
                        <p v-if="currentBundle" class="m-0">Current Bundle:&nbsp;{{ chosenBundle ? chosenBundle.subtitle : currentBundle }}</p>
                        <p v-if="itemPrice" class="m-0">Monthly Price:&nbsp;R {{ parseFloat(itemPrice.price / 100.00).toFixed(2) }}</p>
                    </div>
                    <div class="info-subjects">
                        <span class="heading">Selected Subjects</span>

                        <ul>
                            <li v-for="(selectedSubject) in selectedSubjects" :key="selectedSubject">{{ selectedSubject }}</li>
                        </ul>
                    </div>
                </div>

                <small class="d-block mb-3 text-white">
                    Changes to bundles and/or subjects are effective within 2 hours from receiving your request. When changing bundle, you will be billed from the 1st of the next month.
                </small>

                <div v-if="error" v-html="errorMsg" class="alert alert-danger">

                </div>

                <div v-if="success" v-html="success" class="alert alert-success"></div>

                <div v-if="!canUpdateBundle && canUpdateSubjects" class="alert alert-info">You've already updated your Bundle within the last month. You'll only be able to update your Subjects.</div>

                <div v-if="!canUpdateSubjects && canUpdateBundle" class="alert alert-info">You've already updated your Subjects within the last month. You'll only be able to update your Bundle.</div>

                <div v-if="!canUpdateSubjects && !canUpdateBundle" class="alert alert-info">You've already updated your Bundle and Subjects within the last month.</div>
            </template>

            <template v-else>
                <div class="alert alert-warning">Managing your Bundle and/or Subjects has been disabled due to your learning being {{ isPaused ? 'paused' : 'suspended' }}.</div>
            </template>
            <vi-button-spinner
                :buttonClass="{
                    'new-btn': true,
                    'new-btn-dark': true,
                    'w-auto': true,
                    'px-5': true,
                }"
                buttonText="Manage my bundle/subjects"
                :loading="loading"
                @button-click="() => openSubjectSelectionModal()"
                :disabled="isDisabled || (!canUpdateSubjects && !canUpdateBundle)"
            ></vi-button-spinner>
        </template>

        <div v-else-if="initializing === true" class="placeholder-glow">
            <span class="placeholder w-75"></span>
            <span class="placeholder w-50"></span>
            <span class="placeholder w-75"></span>
            <span class="placeholder w-25"></span>
            <span class="placeholder w-25"></span>
        </div>

        <div v-else class="alert alert-danger">
            An error occured loading this section. Please contact support if this problem persists at <a href="mailto:techsupport@uctohs.valentureonline.com">techsupport@uctohs.valentureonline.com</a>.
        </div>

        <!-- Subject Selection Modal -->
        <div ref="subjectSelectionModal" class="modal fade" id="subjectSelectionModal" tabindex="-1" aria-labelledby="subjectSelectionModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="subjectSelectionModalLabel">Subject Selection</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">
                        <template
                            v-if="initializing === false"
                        >
                            <adult-matric-subject-selection
                                :subjects="subjectSelectionScreen.subjects"
                                :selection-info="subjectSelectionScreen.selectionInfo"
                                :submit-url="subjectSelectionScreen.submitUrl"
                                :bundle-info="subjectSelectionScreen.bundleInfo"
                                class="subject-selection-container"
                                @subject-selection-bundle="onBundleSelection"
                                @subject-selection-fal="onFalSelection"
                                @subject-selection-subjects="onSubjectSelection"
                                :disable-subjects="!canUpdateSubjects"
                                :disable-bundles="!canUpdateBundle"
                            >
                                <template v-slot:bodyCopy>
                                    <p>When making your subject selections, it is important that you understand the requirements needed to obtain your Amended Senior Certificate. You can read more about this <a href="https://www.education.gov.za/Curriculum/SeniorCertificate.aspx" target="_blank">here</a>.</p>
                                </template>

                                <template v-slot:leftBtn>
                                    <button type="button" data-bs-dismiss="modal" :disabled="!canUpdateSubjects && !canUpdateBundle" class="new-btn new-btn-ghost new-btn-sm mt-2 me-0 me-sm-2">Cancel</button>
                                </template>

                                <template v-slot:rightBtn="slotProps">
                                    <button
                                        type="button"
                                        :disabled="!canUpdateSubjects && !canUpdateBundle"
                                        @click.prevent="() => { onSelectedBundleAndSubjects(slotProps.validate); }"
                                        class="new-btn new-btn-dark new-btn-sm mt-2 me-0 me-sm-2"
                                    >Confirm</button>
                                </template>

                                <template v-slot:disclaimer>
                                    <small class="d-block">
                                        Changes to bundles and/or subjects are effective within 2 hours from receiving your request. When changing bundle, you will be billed from the 1st of the next month.
                                    </small>
                                </template>
                            </adult-matric-subject-selection>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <!-- Subject Selection Confirmation Modal -->
        <div ref="confirmationModal" class="modal fade" id="confirmationModal" tabindex="-1" aria-labelledby="confirmationLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">
                        <p class="m-0 text-center">Thank you for choosing to update your Bundle and/or Subject Selection</p>

                        <p class="mb-4 text-center">Please review the details below can confirm if everything is in order</p>

                        <!-- Bundle Details -->
                        <div v-if="chosenBundle" class="bundle-details text-center pb-3">
                            <span class="d-block fw-bold">Bundle Details</span>
                            <span class="d-block">{{ chosenBundle.subtitle }}</span>
                            <span class="d-block">Monthly Price:&nbsp;R {{ parseFloat(chosenBundle.price / 100.00).toFixed(2) }}</span>
                        </div>

                        <!-- Selected Subjects Details -->
                        <div v-if="chosenSubjects" class="selected-subjects-details text-center  pb-4">
                            <span class="d-block fw-bold">Selected Subjects</span>
                            <span class="d-block" v-for="subject in chosenSubjects" :key="subject">{{ subjectSelectionScreen.subjects.allSubjects[subject] }}</span>
                        </div>
                    </div>

                    <div class="modal-footer confirmation-footer">
                        <button type="button" data-bs-dismiss="modal" class="new-btn new-btn-ghost mt-2 me-0 me-sm-2">Cancel</button>

                        <button
                            type="button"
                            @click.prevent="onSubmitSubjectSelection"
                            class="new-btn new-btn-dark mt-2 me-0 me-sm-2"
                        >Confirm Bundle and Subject Selection</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let endpoints = window.endpoints.changeSubjectsOrBundle;
const defaultError = 'An error occured updating your details. Please contact support if this problem persists at <a href="mailto:techsupport@uctohs.valentureonline.com">techsupport@uctohs.valentureonline.com</a>.';

export default {
    name: 'ChangeSubjectsOrBundle',
    data() {
        return {
            currentBundle: null,
            itemPrice: null,
            selectedSubjects: null,

            subjectSelectionModalInstance: null,
            confirmationModalInstance: null,

            // Start Subject Selection Data
            subjectSelectionScreen: {
                subjects: null,
                selectionInfo: null,
                submitUrl: null,
                bundleInfo: null,
            },
            // End Subject Selection Data

            initializing: false,
            error: null,
            errorMsg: defaultError,
            success: null,
            loading: false,
            canUpdateBundle: false,
            canUpdateSubjects: true,
            canUpdateSubjectsInitial: true,
            isDisabled: false,
            isSuspended: false,
            isPaused: false,

            newBundle: null,
            newSubjects: [],
            newFal: null,
        };
    },
    async created() {
        this.load();
    },
    computed: {
        chosenBundle() {
            if (!this.subjectSelectionScreen.bundleInfo) {
                return null;
            }

            if (!this.newBundle) {
                return this.subjectSelectionScreen.bundleInfo.find((bundle) => bundle.enum === this.currentBundle);
            }

            return this.subjectSelectionScreen.bundleInfo.find((bundle) => bundle.enum === this.newBundle);
        },
        chosenSubjects() {
            if (!this.chosenBundle || !this.newSubjects) {
                return null;
            }

            let subjects = [ ...this.newSubjects ];

            if (this.chosenBundle.type === 'asc') {
                subjects = [
                    ...subjects,
                    ...Object.keys(this.subjectSelectionScreen.subjects['preSelectedSubjects']),
                    this.newFal,
                ];
            }

            return subjects;
        }
    },
    methods: {
        load() {
            this.initializing = true;
            this.error = false;

            this.init()
                .then(() => {
                    this.initializing = false;

                    this.initSubjectSelectionModal();
                    this.initConfirmationModal();
                })
                .catch(() => {
                    this.initializing = null;
                    this.error = true;
                });
        },
        init() {
            return axios.get(endpoints.init)
                .then((response) => {
                    let data = response.data;

                    this.isSuspended = data.isSuspended;
                    this.isPaused = data.isPaused;
                    this.isDisabled = data.disabled;
                    this.currentBundle = data.bundle;
                    this.itemPrice = data.itemPrice;
                    this.selectedSubjects = data.selectedSubjects;
                    this.canUpdateSubjects = data.canUpdateSubjects;
                    this.canUpdateSubjectsInitial = data.canUpdateSubjects;
                    this.canUpdateBundle = data.canUpdateBundle;

                    this.subjectSelectionScreen.subjects = data.subjectSelectionScreen.subjects;
                    this.subjectSelectionScreen.selectionInfo = data.subjectSelectionScreen.subjectSelectionInfo;
                    this.subjectSelectionScreen.bundleInfo = data.subjectSelectionScreen.bundleInfo;
                    this.subjectSelectionScreen.submitUrl = data.subjectSelectionScreen.submitUrl;
                });
        },
        onSubmitSubjectSelection() {
            this.closeConfirmationModal();

            const subjectData = {
                subjects: this.newSubjects,
                bundle_type: this.newBundle,
            };

            if (this.chosenBundle.type === 'asc') {
                subjectData.additional_language = this.newFal;

                if (this.newFal) {
                    let idx = this.chosenSubjects.indexOf(this.newFal);

                    if (idx > -1) {
                        let chosen = this.chosenSubjects;
                        this.chosenSubjects.splice(idx, 1);

                        subjectData.subjects = chosen;
                    }
                }
            }

            this.loading = true;
            this.success = null;
            this.errorMsg = defaultError;

            axios.post(endpoints.submit, subjectData)
                .then((response) => {
                    if (response.status == 204) {
                        this.success = "There were no changes in your new Bundle and Subjects selection.";
                    } else {
                        this.success = "We are processing your request. Please allow up to 2 hours for your new subjects to appear on your Online Campus.";
                    }

                    this.load();
                })
                .catch((error) => {
                    let data = error.response.data;

                    if (data.hasOwnProperty('error')) {
                        this.errorMsg = data.error;
                    }

                    this.error = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // When a user has selected new subjects and/or bundle.
        onSelectedBundleAndSubjects(validate) {
            let isValid = validate();

            if (!isValid) {
                return;
            }

            this.closeSubjectSelectionModal();
            this.openConfirmationModal();
        },
        onBundleSelection(event) {
            if (event.type === 'asc') {
                this.newBundle = 'Full ASC';
            } else {
                this.newBundle = `Bundle ${event.bundle}`;
            }
        },
        onFalSelection(fal) {
            this.newFal = fal;
        },
        onSubjectSelection(subjects) {
            this.newSubjects = subjects;
        },
        initSubjectSelectionModal() {
            this.subjectSelectionModalInstance = new bootstrap.Modal(this.$refs.subjectSelectionModal, {});
        },
        openSubjectSelectionModal() {
            this.subjectSelectionModalInstance.show();
        },
        closeSubjectSelectionModal() {
            this.subjectSelectionModalInstance.hide();
        },
        disposeSubjectSelectionModal() {
            this.subjectSelectionModalInstance.dispose();
        },

        initConfirmationModal() {
            this.confirmationModalInstance = new bootstrap.Modal(this.$refs.confirmationModal, {});
        },
        openConfirmationModal() {
            this.confirmationModalInstance.show();
        },
        closeConfirmationModal() {
            this.confirmationModalInstance.hide();
        },
        disposeConfirmationModal() {
            this.confirmationModalInstance.dispose();
        },
    },
    watch: {
        newBundle(newVal) {
            if (this.canUpdateSubjectsInitial === true) {
                return;
            }

            if (newVal !== this.currentBundle) {
                this.canUpdateSubjects = true;
                return;
            }

            this.canUpdateSubjects = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';

.billing-heading {
    margin-bottom: 1.5em;
}

.info {
    padding-left: 1rem;
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: 14px;
    color: $white;

    @media screen and (max-width: 768px) {
        grid-template-columns: 100%;
        padding-left: 0;
    }

    &-subjects {
        @media screen and (max-width: 768px) {
            padding-top: 1rem;
        }

        .heading {
            font-weight: 600;
            font-size: 1.2em;
        }
    }
}

ul {
    list-style: none;
    padding: 0;
}

.confirmation-footer {
    justify-content: space-between;

    .new-btn {
        max-width: none;
        padding-left: 3em;
        padding-right: 3em;
        width: auto;

        @media screen and (max-width: 992px) {
            width: 100%;
        }
    }
}

::v-deep {
    .new-btn-ghost {
        color: $pastel-blue;
        border-color: $pastel-blue;
        transition: all 300ms ease;

        &:hover {
            color: $white;
            background-color: $pastel-blue;
        }
    }

    .subject-selection-container {
        max-width: 90%;
        margin: 0 auto;
        font-size: 14px;
    }

    .bundle-container {
        grid-column-gap: 1.5rem;
    }
}
</style>
