<template>
    <div ref="cell" class="w-100 d-flex justify-content-center align-items-center px-2 py-4 h-100 table-cell" data-bs-toggle="modal" data-bs-target="#weekly-attendance" :data-module-number="mod" :data-student-email="studentEmail" role="button"  v-on:mouseover="event => toggleHover(event)">
        <img v-if="state == 'completed'" class="module-icon" :data-student-email="studentEmail" :data-module-number="mod" :src="'/portal-data/uct/tick.svg'" alt="Tick Icon">
        <img v-if="state == 'upcoming'" class="module-icon" :data-student-email="studentEmail" :data-module-number="mod" :src="'/portal-data/uct/pencil.svg'" alt="Pencil Icon">
        <img v-if="state == 'outstanding'" class="module-icon" :data-student-email="studentEmail" :data-module-number="mod" :src="'/portal-data/uct/x.svg'" alt="X Icon">
    </div>
</template>

<script>

export default {
    name: "ProgressTableCell",
    data() {
        return {};
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        state: {
            type: String,
        },
        mod: {
            type: Number,
            required: true,
        },
        studentEmail: {
            type: String,
            required: true,
        },
    },
    mounted() {
        // Scroll current module into view after current term has been scrolled into view.
        if (this.active) {
            setTimeout(() => {
                this.$refs.cell.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }, 250);
        }
    },
    methods: {
        toggleHover(event) {
            const moduleHoverEvent = new CustomEvent('moduleHover', { detail: parseInt(event.target.dataset.moduleNumber) });
            document.dispatchEvent(moduleHoverEvent);
        }
    }
}

</script>

<style lang="scss" scoped>

@import 'resources/sass/abstract/colours.scss';

.table-row .active {
    background-color: $light-blue-F0;

    @media (max-width: 767.98px) {
        background-color: $light-blue-F0;
    }
}

.module-icon {
    width: 32px;

    @media (max-width: 991.98px) {
        width: 20px;
    }
}

.table-cell {
    border-top: none;
    height: 80px;

    @media (max-width: 767.98px) {
        background-color: $white;
        border-top: 1px solid $grey-d8;
    }
}

</style>
