<template>
    <div
        :id="`block-${student.id}`"
        class="accordion-collapse learner-accordian-content"
        :class="{'collapse': !isExpanded, 'show' : isExpanded}"
        :aria-labelledby="`heading-${student.id}`"
        data-bs-parent="#learnerAccordian"
    >
        <div class="row widget-row m-0 learner-account">
            <!-- error message -->
            <div class="alert alert-danger text-center" v-if="errorMessage">{{ errorMessage }}</div>

            <div v-else class="learner-information p-4">
                <!-- success message -->
                <div class="alert alert-success mb-4 text-center" v-if="isSuccessful" id="successAlert">
                    Details updated successfully.
                </div>

                <!-- removed from the front end for now as we need to do more work on the learners names before releasing it -->
                <!-- <account-input
                    label-text="First Name"
                    name="FirstName"
                    :init-value="student.firstName"
                    :on-submit="onSubmitHandler.bind(this)"
                    class="mb-3 input-dark"
                    inputClass="account-input-container-dark"
                ></account-input>

                <account-input
                    label-text="Last Name"
                    :student="student"
                    :guardian="guardian"
                    :emergency-contact-submit-url="emergencyContactSubmitUrl"
                    :relationships="relationships"
                    :dial-codes="dialCodes"
                    class="mb-3 input-dark"
                    name="LastName"
                    :init-value="student.lastName"
                    :on-submit="onSubmitHandler.bind(this)"
                    class="mb-3 input-dark pb-2"
                    inputClass="account-input-container-dark"
                ></account-input>-->

                <div class="learner-card shadow">
                    <emergency-contact-form
                        :student="student"
                        :guardian="guardian"
                        :emergency-contact-submit-url="emergencyContactSubmitUrl"
                        :relationships="relationships"
                        :dial-codes="dialCodes"
                        class="mb-3 input-dark"
                    ></emergency-contact-form>
                </div>

                <template v-if="canRequestSubjectChange">
                    <vi-subject-change-request
                        :student-id="student.id"
                    ></vi-subject-change-request>
                </template>

                <div class="learner-card shadow" v-if="isCaps">
                    <vi-withdrawal-section
                        :student="student"
                    ></vi-withdrawal-section>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import AccountInput from './AccountInput.vue';

    export default {
        name: "GuardianLearnerBasicInfoSection",
        components: {
            AccountInput,
        },
        data() {
            return {
                disabled: false,
                isSuccessful: false,
                loadedData: null,
                loading: true,
                errorMessage: null,
                errors: {},
            };
        },
        props: {
            guardian: {
                type: Object,
                required: true,
            },
            submitUrl: {
                type: String,
                required: true,
            },
            student: {
                type: Object,
                required: true,
            },
            isExpanded: {
                type: Boolean,
                default: false
            },
            inputClass: {
                type: String,
            },
            emergencyContactSubmitUrl: {
                type: String,
                required: true,
            },
            relationships: {
                type: Array,
                required: true,
            },
            dialCodes: {
                type: Object,
                required: true,
            },
            canRequestSubjectChange: {
                type: Boolean,
                default: false,
            }
        },
        methods: {
            onSubmitHandler(field, value) {
                let payload = {
                    id: this.student.id,
                };

                payload[field] = value;

                return axios
                    .post(this.submitUrl, payload)
                    .then((response) => {
                        this.isSuccessful = true;
                        setTimeout(() => {
                            this.isSuccessful = false;
                        }, 3000);
                    }).catch((error) => {
                        this.errorMessage = "We had an unexpected error updating this data. Please try refreshing the page or contact us for support.";
                        return;
                    })
            },
        },
        computed: {
            isCaps() {
                return this.student.currentProgramEnrolment != null
                    && this.student.currentProgramEnrolment.hed__Account__r != null
                    && this.student.currentProgramEnrolment.hed__Account__r.Curriculum__c === 'CAPS';
            }
        },
    }
</script>

<style scoped lang="scss">
    @import 'resources/sass/abstract/colours.scss';
    .learner-account {
        padding: 0 40px;
    }

    .learner-full-name {
        font-size: 1.25rem;
    }

    .learner-accordian-content {
        background-color: $white;
    }

    .learner-information {
        display: grid;
        grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
        grid-gap: 1rem;
    }

    .learner-card {
        padding: 1rem 1rem;
        border-radius: 16px;
    }

    @media screen and (max-width: 1263px) {
        .learner-information {
            grid-template-columns: 1fr;
        }

    }
</style>
