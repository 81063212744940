<template>
    <div class="vi-subject-selection">
        <vi-subject-group
            v-for="(subjectGroup, index) in selection.subject_groups"
            :subject-group="subjectGroup"
            :key="index"
            :grade-of-entry="gradeOfEntry"
            @subject-selection="(event) => onSubjectSelection(event, index)"
            @subject-change="onSubjectChange"
            :subject-change-validation-required="subjectChangeValidationRequired"
        ></vi-subject-group>
    </div>
</template>

<script>
export default {
    name: 'ViSubjectSelection',
    props: {
        subjectSelection: {
            type: Object,
            required: true,
        },
        gradeOfEntry: {
            type: String,
            default: null,
        },
        subjectChangeValidationRequired: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            selection: this.subjectSelection,
        };
    },
    methods: {
        onSubjectSelection(event, subjectGroupIndex) {
            this.updateSubject(event.code, event.isSelected, subjectGroupIndex);

            this.$emit('subjects-selected', this.selectedSubjectCodes);
        },
        updateSubject(code, isSelected, index = null) {
            this.selection.subject_groups.forEach(group => {
                group.subjects.forEach(subject => {
                    if (subject.code === code) {
                        subject.is_selected = isSelected;
                    }
                });
            });
        },
        onSubjectChange(event) {
            this.$emit('subject-change', event);
        }
    },
    computed: {
        selectedSubjects() {
            let subjects = [];

            this.selection.subject_groups.forEach(group => {
                subjects = subjects.concat(group.subjects);
            });

            return subjects.filter(subject => subject.is_selected);
        },
        selectedSubjectCodes() {
            let subjectSet = new Set(this.selectedSubjects.map(subject => subject.code));

            return Array.from(subjectSet);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
