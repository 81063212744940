<template>
    <div class="mb-3">
        <h4 class="mb-3 text-dark">Payment Summary</h4>
        <h5 class="mb-3 text-dark">Plan</h5>
        <p>{{ curriculum }} Tuition Fees</p>

        <div
            v-if="monthlyItemPrice"
            class="payment-card-container p-3 rounded mb-3 shadow-sm d-flex justify-content-between"
        >
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="paymentSummaryChecks"
                    value="monthly"
                    id="monthlyPayment"
                    aria-describedby="monthlyPaymentText"
                    v-model="value"
                    :disabled="isActive"
                >

                <label class="form-check-label" for="monthlyPayment">
                    <span class="fw-bold">Monthly</span> tuition fees
                </label>

                <div id="monthlyPaymentText" class="form-text">
                    {{ getInstallmentsText(monthlyItemPrice) }}
                </div>
            </div>
            <p class="mb-0">
                {{ toCurrency(monthlyItemPrice.currency_code, calculateItemPriceWithCoupon(monthlyItemPrice)) }}
            </p>
        </div>

        <div v-if="addonCharges.quantity > 0">
            <h5 class="mb-3 text-dark">Additional Subject(s)</h5>

            <p class="mb-0">{{ curriculum }} Single Subject x {{ addonCharges.quantity }}</p>

            <div class="d-flex ps-3 justify-content-between">
                <p class="mb-0">Additional Subject(s) will be charged with tuition fees</p>
                <p class="mb-0">{{ toCurrency(monthlyItemPrice.currency_code, addonAmount) }}</p>
            </div>
        </div>

        <hr>

        <p class="p-disclaimer">
            <small><span class="fw-bold">Disclaimer:</span> The prices represented here are an indication of the tuition fees for the current year only and excludes all once off charges. Prices are subject to annual increases.</small>
        </p>

        <hr>

        <h5 class="mb-3 text-dark">Tuition set up</h5>

        <p class="mb-2 ps-1">Tuition of <span class="fw-bold">{{ toCurrency(monthlyItemPrice.currency_code, finalAmount) }}</span> will be billed on <span class="fw-bold">1 January</span>. Enrolments after 1 January will be billed on completion of subject approval.</p>

        <p class="fs-14 ps-2">Remainder of instalments will be charged on the first day of each month for the duration of the Learner's journey.</p>
    </div>
</template>

<script>
    export default {
        name: "PaymentSummary",
        data: () => ({
            value: 'monthly',
        }),
        props: {
            yearlyItemPrice: {
                type: Object,
            },
            monthlyItemPrice: {
                type: Object,
            },
            months18ItemPrice: {
                type: Object,
            },
            addonCharges: {
                type: Object,
                required: true,
            },
            curriculum: {
                type: String,
                required: true,
            },
            isActive: {
                type: Boolean,
                default: false,
            },
            chosenPlan: {
                type: String,
                default: null,
            },
            startDate: {
                type: String,
                required: true,
            },
            tuitionFeeCoupons: {
                type: Array,
                required: true,
            },
            startingTerm: {
                type: String,
                required: true,
            },
        },
        methods: {
            toCurrency(currency, value) {
                if (typeof value !== "number") {
                    return value;
                }

                var formatter = new Intl.NumberFormat(undefined, {
                    style: 'currency',
                    currency: currency
                });

                return formatter.format(value).replace(',', ' ');
            },
            hasTuitionFeeCoupon(planId) {
                let coupon = this.tuitionFeeCoupons.find(tuitionFeeCoupon => {
                    return tuitionFeeCoupon.allowedPlanIds.includes(planId);
                });

                return !!coupon;
            },
            getTuitionFeeCoupon(planId) {
                return this.tuitionFeeCoupons.find(tuitionFeeCoupon => {
                    return tuitionFeeCoupon.allowedPlanIds.includes(planId);
                });
            },
            calculateItemPriceWithCoupon(itemPrice) {
                let hasCoupon = this.hasTuitionFeeCoupon(itemPrice.id);

                if (!hasCoupon) {
                    return this.fromCentsToRands(itemPrice.price);
                }

                let coupon = this.getTuitionFeeCoupon(itemPrice.id);
                let discountAmount = this.calculateDiscount(itemPrice.price, coupon);

                return this.fromCentsToRands(itemPrice.price - discountAmount);
            },
            calculateDiscount(price, coupon) {
                switch (coupon.discountType) {
                    case 'percentage':
                        return price * (coupon.discountPercentage / 100);
                    default:
                        0;
                }
            },
            fromCentsToRands(value) {
                return Math.floor(value) / 100;
            },
            getInstallmentsText(itemPrice) {
                if (itemPrice.billing_cycles < 0) {
                    console.error(`Invalid amount of billing cycles for item price plan. Item Price: ${JSON.stringify(itemPrice)}`);
                    return '';
                }

                if (itemPrice.billing_cycles === 1) {
                    return 'Once off payment';
                }

                return `${itemPrice.billing_cycles} instalments`;
            }
        },
        mounted() {
            if (this.chosenPlan) {
                this.value = this.chosenPlan;
            }

            this.$emit('input', this.value);
        },
        computed: {
            addonAmount() {
                let quantity = this.addonCharges.quantity;

                if (this.addonCharges.quantity <= 0) {
                    return 0;
                }

                let addonPlan = this.addonCharges.items[this.value];

                return addonPlan.price * quantity / 100;
            },
            finalAmount() {
                let selectedItemPrice = this.calculateItemPriceWithCoupon(this[`${this.value}ItemPrice`]);
                return selectedItemPrice + this.addonAmount;
            }
        },
        watch: {
            value(value) {
                this.$emit('input', value);
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '../../../sass/abstract/colours.scss';
@import '../../../sass/utilities/rem-calc.scss';

.payment-card-container {
    background-color: $white;
}

.fs-14 {
    font-size: rem-calc(14);
}
</style>
