export default {
    data() {
        return {
            errorHandlingSettings: {
                selector: '.invalid-feedback',
            },
        };
    },
    methods: {
        hasError(property) {
            if (!this.errors) {
                return false;
            }

            return this.errors.hasOwnProperty(property) && Array.isArray(this.errors[property]) && this.errors[property].length > 0;
        },
        getErrors(property) {
            if (!this.hasError(property)) {
                return null;
            }

            return this.errors[property];
        },
        clearError(property) {
            if (this.errors && this.errors.hasOwnProperty(property)) {
                delete this.errors[property];

                if (Object.keys(this.errors).length <= 0) {
                    this.errors = null;
                }
            }
        },
        firstError() {
            return Array.from(document.querySelectorAll(this.errorHandlingSettings.selector))
                .filter(node => {
                    if (node.hasOwnProperty('checkVisibility')) {
                        return node.checkVisibility({ opacityProperty: true, visibilityProperty: true });
                    }

                    return true;
                })[0];
        },
    },
    computed: {}
}