<template>
    <div class="vi-subject-change-request learner-card shadow">
        <h5 class="text-pastel-blue fw-bold mb-3">Request Subject Change</h5>

        <div v-if="requests && requests.length > 0" class="requests">
            <div
                v-for="request in requests"
                :key="request.ref"
                class="request"
            >
                <div><span>Request Date:</span>&nbsp;{{ request.date }}</div>
                <div><span>Request Ref:</span>&nbsp;{{ request.ref }}</div>
                <div><span>Request Status:</span>&nbsp;{{ request.status }}</div>
            </div>
        </div>

        <div v-if="error" class="alert alert-danger mb-3">
            An error occured trying to load your existing requests. If this problem persists please contact support.
        </div>

        <button class="btn uct-dark rounded-pill w-100 px-4" data-bs-toggle="modal" :data-bs-target="`#requestSubjectChange-${studentId}`">Request Subject Change</button>
    </div>
</template>

<script>
export default {
    name: "SubjectChangeRequest",
    props: {
        studentId: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            requests: [],
            error: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {

            axios.get(`${window.endpoints.subjectChangeRequest.init}?learnerSfId=${this.studentId}`)
                .then(response => {
                    this.requests = response.data.requests;
                })
                .catch(error => {
                    this.error = true;
                });
        }
    },
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';

.uct-dark {
    background-color: $pastel-blue;
    color: $white;

    &:hover {
        background-color: $pastel-light-blue;
    }
}

.requests {
    margin-bottom: 1rem;
}

.request {
    font-size: 14px;
    padding: 0.5rem 1rem;
    background-color: lighten($bluef7, 5%);
    border-radius: 16px;

    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    span {
        font-weight: 600;
    }
}

</style>
