<template>
    <section class="weekly-summary-container m-3">
        <div class="weekly-summary-header row my-0">
            <div class="col-0 col-lg-4 p-0 m-0"></div>

            <div class="weekly-summary-title-bar col-lg-4">
                <i :class="{'opacity-0' : !canDecreamentModule}" class="bi bi-chevron-left circle-border-icon" @click="canDecreamentModule && moduleNumber--"></i>
                <h2 class="m-0 module-header">Module {{ moduleNumber > 0 ? moduleNumber : '' }}</h2>
                <i :class="{'opacity-0': !canIncreamentModule}" class="bi bi-chevron-right circle-border-icon" @click="canIncreamentModule && moduleNumber++"></i>
            </div>

            <div class="col-lg-4 d-flex flex-row-reverse align-items-center">
                <i class="bi bi-x-lg" data-bs-dismiss="modal"></i>
            </div>
        </div>

        <test-email-field v-if="!isProduction" v-on:reload-data="updateSubstituteEmail" ></test-email-field>

        <p class="weekly-view mb-0 mt-4 text-center">Weekly View</p>
        <p class="dates-ranges text-center" v-if="displayData.length > 0 && !loading">{{ weeks }}</p>

        <div class="legend px-4 mb-0" v-if="displayData.length > 0 && !loading">

            <div class="content-block w-100">

                <div class="d-flex align-items-center">
                    <span class="legend-icon legend-formal-assessment"></span> {{ formalAssessmentCount }} Formal Assessments this week.
                </div>

                <hr>

                <h2>AMA - Ask Me Anything</h2>
                <p>Compulsory weekly live sessions between all learners and their Subject Specialists.</p>
                <h2>MB - Mastery Bootcamp</h2>
                <p>Scheduled for learners who have not attempted or failed their second Mastery Check.</p>
                <h2>Attendance Policy</h2>
                <p class="mb-0">Learners are considered present in a live session if they attend 80% or more of the session.</p>

            </div>
        </div>

        <div class="col-12" v-if="displayData.length > 0 && !loading">
            <select name="filterSubject" id="filterSubject" class="subject-select form-select form-select-lg" v-model="filterSubject">
                <option value="All">All Subjects</option>
                <option v-for="(subject, key) in subjects" :value="subject" :key="key">{{ subject }}</option>
            </select>
        </div>
 
        <div class="table-headers p-0 m-3 mb-0 row border d-none d-lg-flex" v-if="displayData.length > 0 && !loading">
            <div class="col-3 border-end d-flex align-items-center justify-content-center">Subject</div>
            <div class="col-3 border-end d-flex align-items-center justify-content-center">Assessment Information</div>
            <div class="col-1 border-end d-flex align-items-center justify-content-center">Days Accessed</div>
            <div class="col-1 border-end d-flex align-items-center justify-content-center">Lessons Completed</div>
            <div class="col-2 border-end d-flex align-items-center justify-content-center">AMA Attendance</div>
            <div class="col-2 border-end d-flex align-items-center justify-content-center">MB Attendance</div>
        </div>

        <div class="assessments px-3 px-lg-0 pb-2 py-lg-0 mx-lg-3" v-if="!loading">
            <article v-for="(assessment, index) in displayData" class="row assessment-row"
                :class="{ 'is-formal-assessment': assessment.assessmentType == 'Formal Assessment', 'd-none': filterSubject != 'All' && assessment.subject != filterSubject }" :key="index">

                <div class="col-12 col-lg-3 align-items-center d-flex text-light px-3 subject-name">{{ assessment.subject }}</div>

                <div
                    class="col-12 col-lg-3 subject-assessment px-3 right-border d-flex align-items-center bottom-border py-3 py-lg-2">
                    <p class="fw-bold text-center mb-0" v-if="!assessment.assessmentType">No Assessments</p>
                    <div v-else>
                        <div class="assessment-name text-start"><span class="fw-bold">{{ assessment.assessmentType }}:</span> {{ assessment.assessmentName }}</div>
                        <div class="completion-date text-start">
                            <template v-if="assessment.completed != `null`"><span class="fw-bold"> Completed:</span> {{ assessment.completed }}</template>
                            <span v-else class="fw-bold">Not Completed</span>
                        </div>
                    </div>

                </div>

                <div
                    class="col-12 col-lg-1 d-flex flex-column align-items-start align-items-lg-center justify-content-lg-center px-3 right-border bottom-border py-3 py-lg-2">
                    <p class="fw-bold text-center mb-0 d-lg-none">Days Accessed</p>
                    <div class="d-inline-flex align-items-start align-items-lg-center justify-content-center wrap">
                        <div v-for="(day, key) in assessment.attendance" :key="key" class="fw-bold attendance-days"
                        :class="{ 'attended': day.accessed }">{{ day.day }}</div>
                    </div>
                </div>

                <div class="col-12 col-lg-1 px-3 right-border d-flex align-items-center bottom-border py-3 py-lg-2">
                    <div class="d-flex align-items-center">
                        <div class="lessons-pie-chart pe-2">
                            <canvas :id="`lessons-pie-chart-${index}`" width="30" height="30"></canvas>
                        </div>

                        <div class="lessons-completed">
                            <div class="lessons-completed-title text-start fw-bold text-start d-lg-none">Lessons Completed</div>
                            <div class="lessons-completed-number text-start">{{ assessment.lessonsCompleted }}%</div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-2 border-end bottom-border px-3 py-3 py-lg-2">
                    <p class="fw-bold text-lg-center mb-2 d-lg-none">AMA Attendance</p>

                    <p v-for="(attendance, key) in amaAndEngagement(assessment.amaAttendance)" :key="key" class="mb-1">
                        <small> <strong>{{ attendance.date }}</strong> {{  attendance.status ? ` : ${attendance.status}` : '' }} </small>
                    </p>
                </div>

                <div class="col-12 col-lg-2 border-end px-3 py-3 py-lg-2">
                    <p class="fw-bold text-lg-center mb-2 d-lg-none">MB Attendance</p>

                    <p v-for="(engagement, key) in amaAndEngagement(assessment.weeklyEngagement)" :key="key" class="mb-1">
                        <small> <strong>{{ engagement.date }}</strong> {{  engagement.status ? ` : ${engagement.status}` : '' }} </small>
                    </p>
                </div>

            </article>
        </div>

        <div v-if="loading" class="text-center my-5">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else-if="unexpectedError || !hasData" class="text-center my-4">
            <p v-if="unexpectedError" class="text-danger">An unexpected error occurred. Please try again later.</p>
            <p v-else-if="!hasData && moduleNumber > 0">No data available for this module.</p>
            <p v-else-if="!hasData && moduleNumber == 0">No data available for this week's module.</p>
        </div>

    </section>

</template>

<script>

export default {
    name: "ViWeeklyAttendance",
    props: {
        lookerUrl: {
            type: String,
            default: null,
        },
        isProduction: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        loading: false,
        studentEmail: null,
        errors: null,
        unexpectedError: false,
        weeks: null,
        moduleNumber: 0,
        hasData: false,
        displayData: [],
        subjects: [],
        filterSubject: 'All',
    }),
    computed: {
        canIncreamentModule() {
            return  !this.loading && (this.moduleNumber == 0 || this.hasData);
        },
        canDecreamentModule() {
            return !this.loading && this.moduleNumber > 1;
        },
        formalAssessmentCount() {
            if (this.filterSubject !== 'All') {
                return this.displayData.filter((data) => data.assessmentType === 'Formal Assessment' && data.subject === this.filterSubject).length;
            }
            return this.displayData.filter((data) => data.assessmentType === 'Formal Assessment').length;
        },
    },
    mounted() {
        document.body.addEventListener('weekly-attendance.open', async (e) => {
            this.moduleNumber = e.detail.moduleNumber;
            this.studentEmail = e.detail.studentEmail;

            await this.fetchWeeklyAttendance();
        });
    },
    watch: {
        'moduleNumber': function (val) {
            this.fetchWeeklyAttendance();
        },
    },
    methods: {
        async updateSubstituteEmail(email) {
            this.studentEmail = email;
            await this.fetchWeeklyAttendance();
        },
        async fetchWeeklyAttendance() {
            this.loading = true;
            try {
                this.unexpectedError = false;
                this.errors = null;
                let fetchUrl = this.lookerUrl.replace('studentEmail', this.studentEmail).replace('moduleNumberValue', this.moduleNumber)
    
                var response = await axios.get(fetchUrl, {
                    moduleNumber: this.moduleNumber,
                    email: this.studentEmail,
                });

                if (response.data.data && response.data.data.attendanceData && response.data.data.attendanceData.length > 0) {
                    this.moduleNumber = response.data.data.moduleNumber;
                    this.weeks = response.data.data.weekRange;
                    this.displayData = response.data.data.attendanceData ?? [];
                    this.hasData = true;

                    this.subjects = this.displayData.map((data) => data.subject);

                    this.$nextTick(function () {
                        this.displayData.forEach((data, index) => {
                            this.generatePieChart(index, data.lessonsCompleted, data.subject);
                        });
                    });
                } else {
                    this.displayData = [];
                    this.hasData = false;
                }

            } catch (error) {
               
                if (error.response && error.response.status === 422) {
                    const data = error.response.data;
                    this.errors = data.errors;
                } else {
                    this.unexpectedError = true;
                }
            }

            this.loading = false;
        },
        generatePieChart(key, value) {
            const data = {
                labels: [
                    'Attended',
                    'Not Attended',
                ],
                datasets: [{
                    label: null,
                    data: [value, 100 - value],
                    backgroundColor: [
                        '#30C76C',
                        '#FFFFFF'
                    ],
                }],
            };

            const config = {
                type: 'pie',
                data: data,
                options: {
                    events: [],
                    plugins: {
                        legend: {
                            display: false,
                        },
                    }
                }
            };

            // existing chart
            var myChart = Chart.getChart(`lessons-pie-chart-${key}`)

            if (myChart) {
                myChart.data.datasets[0].data = [value, 100 - value];
                myChart.update();
                return;
            }

            const ctx = document.getElementById(`lessons-pie-chart-${key}`);

            if (ctx) {
                let myChart = new Chart(ctx, config);
                myChart.resize(30, 30);
            }
        },
        amaAndEngagement(lookervalue) {
            if (lookervalue) {
                return lookervalue.split(';').map((day) => {
                    let dayParts = day.split(':');
                    let status = dayParts[1];

                    if (new Date(dayParts[0]) > new Date()) {
                        status = 'Upcoming';
                    }

                    return { date: dayParts[0], status: status };
                });
            }

            return [];
        },
    }
}

</script>
<style lang="scss" scoped>

@import 'resources/sass/abstract/colours.scss';

.subject-select {
    display: none;
}

.weekly-summary-container {
    border-radius: 15px;
    background-color: $white;
    color: $pastel-dark-grey;
    box-shadow: 0px 0px 10px 0px $grey-da;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-color: $grey-da transparent;
    scrollbar-width: thin;
}

.is-formal-assessment {
    background-color: $greenF0;
}

.weekly-view {
    font-size: 20px;
    font-weight: bold;
    color: $blue9B;
}

.dates-ranges {
    font-size: 14px;
}

.weekly-summary-header {
    background-color: $blue9B;
    padding: 10px;
}

.subject-name {
    background-color: $blue9B;
    border-bottom: solid 1px $white;
    min-height: 56px;
}

.right-border {
    border-right: solid 1px $grey-da;
}

.weekly-summary-title-bar {
    display: flex;
    justify-content: space-between;
    color: $white;
    align-items: center;
}

.bi {
    color: $white;
}

.circle-border-icon {
    border: solid 2px $white;
    border-radius: 50%;
    width: 34px;
    display: flex;
    height: 34px;
    align-items: center;
    justify-content: center;
}

.subject-assessment,
.lessons-completed,
.attendance {
    font-size: 14px;
}

.attendance .bi {
    font-size: 32px;
}

.attendance {
    font-size: 14px;
    font-weight: bold;
}

.module-header {
    font-size: 18px;
}

.attendance-days {
    font-size: 14px;
    color: $greyCC;
    letter-spacing: 3px;
}

.attendance-days.attended {
    color: $green6C;
}

.assessment-row {
    margin: 0px;
    border-top: solid 1px $grey-da;
}

.lessons-pie-chart {
    canvas {
        border: solid 1px $black;
        border-radius: 50%;
    }
}

.legend {
    font-size: 14px;
    display: flex;
    justify-content: start;

    &-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
        border: solid 1px $green6C;
    }

    &-formal-assessment {
        background-color: $greenF0;
    }
}

.d-lg-none {
    display: flex !important;
}

.d-none {
    display: none !important;

}

@media screen and (min-width: 1264px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-flex {
        display: flex !important;
    }
}

@media screen and (max-width: 1263px) {
    .subject-select {
        display: block;
        max-width: 600px;
        margin: auto;
        border-radius: 15px;
        border: solid 2px #234682;
        color: #234682;
        font-size: 17px;

        .focus {
            box-shadow: none; 
        }
    }

    .weekly-summary-header {
        flex-direction: column-reverse;
    }
    .assesments {
        display: block;
    }
    .assessment-row {
        border-radius: 15px;
        margin: 30px auto;
        max-width: 600px;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px $grey-da;
        border-top: none;
    }

    .bottom-border {
        border-bottom: solid 1px $grey-da;
    }

    .attendance .bi {
        font-size: 25px;
    }

    .right-border {
        border-right: none;
    }
}

.content-block {
    padding: 20px;
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0px 3px 6px 0px #00000029;

    h2 {
        color: $blue9B;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 4px;
        font-weight: 600;
    }

    p {
        font-weight: 500;
        font-size: 12px;
        padding: 0.25rem 0.5rem;
    }
}

.table-headers {
    background-color: $blue9B;
    color: $white;
    font-weight: 600;
}
</style>
