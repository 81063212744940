export default {
  dynamicFields: [
    {
      name: "regradeRequest",
      label: "Regrade request",
      description: "Please fill out the form below in order to request a regrade for this assignment. Please note that you need to provide sufficient reasoning as to why this assignment should be regraded in order for this query to be processed. For example, references to memos or course content to explain why you believe the mark you received is incorrect. Failure to provide this information will result in this query being denied.",
      type: "grading_query",
      fields: [
        {
          label: "Explanation of grounds for regrade",
          description: 'Please give your reasoning for why you believe you should have received more marks for these questions than you did. Failure to provide sufficient justification will result in this query being denied.',
          type: "textarea",
          required: true,
          name: "regradeExplanation",
        },
        {
          label: "Number of questions to regrade",
          description: 'Please state how many questions you would like us to regrade. If requesting a full paper regrade, say "All".',
          type: "text",
          min: 1,
          required: true,
          name: "numQuestionsToRegrade",
        },
        {
          label: "Questions to regrade",
          description: 'Please list all the questions you would like us to regrade. If requesting a full paper regrade, say "All".',
          type: "text",
          required: true,
          name: "questionsToRegrade",
        },
        {
          label: "Number of marks affected (by regrade request)",
          description: 'Please indicate how many extra marks you think you should have received',
          type: "number",
          min: 1,
          required: true,
          name: "numMarksAffected",
        },
        {
          label: "Acknowledgement that mark can go up or down or stay the same",
          description: "Acknowledgement that mark can go up or down or stay the same",
          type: "checkbox",
          default: false,
          required: true,
          name: "acknowledgeMarkChange",
        },
        {
          label: "Document upload",
          description: "Please upload any supporting documentation",
          type: "file",
          required: true,
          name: "supportingDocumentation",
        }
      ],
    },
    {
      name: "addingError",
      label: "Adding error",
      description: "Please fill out the form below when you have identified an adding error in your assessment.",
      type: "grading_query",
      fields: [
        {
          label: "Explanation about the adding error",
          description: 'Please give a detailed explanation of the reason behind your query so that we can ensure that we have all the information we need in order to investigate your query fully.',
          type: "textarea",
          required: true,
          name: "addingErrorExplanation",
        },
        {
          label: "Mark according to your adding",
          description: 'Please state the mark you believe you should have received after adding up your marks yourself',
          type: "number",
          min: 0,
          max: 100,
          required: true,
          name: "markForAdding",
        },
        {
          label: "Questions where there are adding errors",
          description: 'Please list all questions where there are adding errors',
          type: "text",
          required: true,
          name: "questionsWithAddingError",
        },
        {
          label: "Acknowledgement that mark can go up or down or stay the same",
          description: "Acknowledgement that mark can go up or down or stay the same",
          type: "checkbox",
          default: false,
          required: true,
          name: "acknowledgeMarkChange",
        },
        {
          label: "Document upload",
          description: "Please upload any supporting documentation",
          type: "file",
          required: true,
          name: "supportingDocumentation",
        }
      ],
    },
    {
      name: "portionNotMarked",
      label: "Portion of script not marked",
      description: "Please fill out the form below when you think that a marker has missed a portion of your script and as a result, your full script has not been marked.",
      type: "grading_query",
      fields: [
        {
          label: "Explanation about the portion of script not marked",
          description: 'Please give a detailed explanation of the reason behind your query so that we can ensure that we have all the information we need in order to investigate your query fully.',
          type: "textarea",
          required: true,
          name: "portionNotMarkedExplanation",
        },
        {
          label: "Number of questions not marked",
          description: 'Please indicate how many questions the marker did not mark',
          type: "number",
          min: 1,
          required: true,
          name: "numQuestionsNotMarked",
        },
        {
          label: "Questions & Page Numbers to mark",
          description: 'Please list all questions and page numbers that the marker did not mark',
          type: "text",
          required: true,
          name: "questionsToMark",
        },
        {
          label: "Number of marks affected",
          description: 'Please indicate how many marks are missing from your overall mark due to these questions not being marked',
          type: "number",
          min: 1,
          required: true,
          name: "numMarksAffected",
        },
        {
          label: "Document upload",
          description: "Please upload any supporting documentation",
          type: "file",
          required: true,
          name: "supportingDocumentation",
        }
      ],
    },
    {
      name: "noMarkedScript",
      label: "No marked script attached",
      description: "Please fill out the form below when you have received a mark for an assessment, but your marked script has not been attached to your feedback.",
      type: "grading_query",
      fields: [
        {
          label: "Explanation of query",
          description: 'Please give a detailed explanation of the reason behind your query so that we can ensure that we have all the information we need in order to investigate your query fully.',
          type: "textarea",
          required: true,
          name: "noMarkedScriptExplanation",
        },
        {
          label: "Document upload",
          description: "Please upload any supporting documentation",
          type: "file",
          required: true,
          name: "supportingDocumentation",
        }
      ],
    },
    {
      name: "incorrectMarkedScriptAttached",
      label: "Incorrect marked script attached",
      description: "Please fill out the form below when the assessment that was graded is different to the assessment that you submitted on the OLC/TIA.",
      type: "grading_query",
      fields: [
        {
          label: "Explanation about the wrongly graded paper",
          description: 'Please give a detailed explanation of the reason behind your query so that we can ensure that we have all the information we need in order to investigate your query fully.',
          type: "textarea",
          required: true,
          name: "incorrectMarkedScriptAttachedExplanation",
        },
        {
          label: "Document upload",
          description: "Please upload any supporting documentation",
          type: "file",
          required: true,
          name: "supportingDocumentation",
        }
      ],
    },
    {
      name: "incorrectZero",
      label: "Incorrect 0 given to learner",
      description: "Please fill out the form below when you feel that you incorrectly received a mark of 0 for this assessment.",
      type: "grading_query",
      fields: [
        {
          label: "Explanation/more details about incorrect 0",
          description: "Please give a detailed explanation of the reason behind your query so that we can ensure that we have all the information we need in order to investigate your query fully.",
          type: "textarea",
          required: true,
          name: "incorrectZeroExplanation",
        },
        {
          label: "Reason for incorrect 0",
          description: "Please select the reason why you believe you incorrectly got 0 for this assessment<br>a) The marker says my script is not legible, but it is legible<br>b) The marker says I submitted the wrong/blank script, but I submitted the correct script as a complete scan on the Online Campus and/or TIA",
          type: "text",
          required: true,
          name: "incorrectZeroReason",
        },
        {
          label: "Document upload",
          description: "Please upload any supporting documentation",
          type: "file",
          required: true,
          name: "supportingDocumentation",
        }
      ],
    },
    {
      name: "excusals",
      label: "Excusals",
      description: "Please provide a detailed explanation of the reason behind your request for an excusal so that we can ensure that we have all the information we need to investigate the request fully.",
      type: "excusal",
      fields: [
        {
          label: "Please select the reason for the request to be excused",
          description: "Please select the reason for the request to be excused",
          type: "select",
          options: [
            "Physical/emotional health",
            "Trauma",
            "Bereavement",
            "Barriers to learning",
            "Natural disaster",
            "Appointments at court, social services, or other official agencies for five days or more",
            "Infrastructure collapse",
            "Attending a religious or cultural obligation",
            "Representing the country or province in a sporting or cultural event",
          ],
          required: true,
          name: "reasonForExcusal",
        },
        {
          label: "Explanation",
          description: 'Please provide a detailed explanation of the reason behind your request for an excusal so that we can ensure that we have all the information we need to investigate the request fully.',
          type: "textarea",
          required: true,
          name: "wrongPaperGradedExplanation",
        },
        {
          label: "Upload all supporting evidence",
          description: "<p>Please upload <strong>all supporting evidence</strong> that is necessary to accompany your request.</p><p>Failure to upload the correct supporting evidence wil result in this request being denied immediately.</p><p>The supporting evidence required can be found in the description of this form at the top of this page, or in the <a href='https://infohub.uctonlinehighschool.com/en/articles/7257367-appealing-academic-results-at-uct-online-high-school' target='_blank'>Appeals Infohub Article</a>.</p>",
          type: "file",
          required: true,
          name: "supportingDocumentation",
        }
      ],
    },
    {
      name: "resubmissionRequest",
      label: "Resubmission Request",
      description: "",
      type: "resubmissionRequest",
      fields: [],
    },
  ],
};
