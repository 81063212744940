<template>
    <div class="vi-subject-group">
        <vi-subject
            v-for="(subject, index) in group.subjects"
            :subject="subject"
            :key="'subject-group' + isDisabled(subject) + index + subject.is_selected + subject.is_selectable"
            :disabled="isDisabled(subject)"
            :grade-of-entry="gradeOfEntry"
            @subject-selection="onSubjectSelection"
            :subject-change-validation-required="subjectChangeValidationRequired"
            @subject-change="onSubjectChange"
        ></vi-subject>
    </div>
</template>

<script>
export default {
    name: 'ViSubjectGroup',
    props: {
        subjectGroup: {
            type: Object,
            required: true,
        },
        gradeOfEntry: {
            type: String,
            default: null,
        },
        subjectChangeValidationRequired: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            group: this.subjectGroup,
        };
    },
    methods: {
        onSubjectSelection(event) {
            // Propagate event to parent.
            this.$emit('subject-selection', event);
        },
        onSubjectChange(event) {
            this.$emit('subject-change', event);
        },
        isDisabled(subject) {
            if (!subject.is_selectable) {
                // Automatically deselect, unselectable subjects.
                this.$emit('subject-selection', {
                    code: subject.code,
                    isSelected: false,
                });

                return true;
            }

            // Allow currently selected subjects to be unselected.
            if (subject.is_selected) {
                return false;
            }

            let {max} = this.group;
            let numSelected = this.selectedSubjects.length;

            // Check maximum rule
            if (numSelected >= max) {
                return true;
            }

            return false;
        }
    },
    computed: {
        selectedSubjects() {
            return this.group.subjects
                .filter(subject => subject.is_selected)
                .map(subject => subject.code);
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
