export default {
    methods: {
        /**
         * get form data on the basis of applied curriculum
         * @params {object} object
         * @params {string} key, key to look for in object
         * @params {[]} valueArray , valueArray should contain key
         * @params {string} returnKey , value to return in object
         * @return {object}
         */
        filterFormDataBasedOnCurriculum (object, key, valueArray,returnKey) {
            return Object.keys(object).reduce((acc, prop) => {
                if (valueArray.includes(object[prop][key])) {
                    acc[prop] = object[prop][returnKey];
                }
                return acc;
            }, {});
        },

        /**
         * get form data on the basis of applied curriculum
         * @params {string} applicationId
         * @return {promise}
         */
        async getApplication (url){
            let timestamp = new Date().getTime();
            const response = await axios.get(`${url}?t=${timestamp}`);
            return response
        },

         /**
         * prepopulate form data
         * @params {object} data
         * @return {void}
         */
        loadForm(data) {
            if (this.form) {
                _.each(data, (v, i) => {
                    if (typeof v == "boolean") v = Number(v)
                    if (_.isObject(v)) this.loadForm(v);
                    else if (this.form[i]) this.$set(this.form[i], "value", v);
                });
            }
        },
        getCookie(cName) {
            const name = cName + "=";
            const cDecoded = decodeURIComponent(document.cookie); //to be careful
            const cArr = cDecoded.split('; ');
            let res;
            cArr.forEach(val => {
                if (val.indexOf(name) === 0) res = val.substring(name.length);
            })
            return res;
        },
        setCookie(cName,value,expireTimeInSeconds) {
            let date = new Date();
            date.setTime(date.getTime()+(expireTimeInSeconds*1000));
            let expires = "; expires="+date.toGMTString();
            let cookie = cName+"="+value;
            if(typeof(expireTimeInSeconds) != 'undefined') {
                cookie+= expires;
            }
            document.cookie = cookie+"; path=/";
        },
        deleteCookie(cName) {
            document.cookie = cName +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
    }
}
