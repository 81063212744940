<template>
    <div class="vi-success">
        <h3>Subject Change Request Submitted</h3>

        <p>Thank you for submitting your Learner's subject change request. This request is now under review by the Student Records Office. The outcome of your request will be communicated with you as soon as possible. Please check your emails regularly for updates and on the status of this request.</p>

        <p>Feel free to contact your Support Coach for further clarity and assistance.</p>
    </div>
</template>

<script>
export default {
    name: "Success",
    props: {},
    data() {
        return {};
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
h3 {
    margin-bottom: 0.5em;
}

p {
    margin-bottom: 1em;
}

.vi-success {
    max-width: 75ch;
    margin: 0 auto;
}
</style>