<template>
    <div>
        <form id="paystack-payment-form" @submit.prevent="handleFormSubmission" class="guardian-loging-form-container mx-auto">
            <div>
                <h1 class="mb-3 h4 text-dark text-center">Secure Placement</h1>

                <slot name="skipped-academic-review"></slot>

                <h5 class="mb-3 mt-5 text-dark">Terms and Conditions</h5>

                <p>By ticking the boxes below, you are agreeing to the information outlined in our terms and conditions, handbooks and policies.</p>

                <p class="fw-bold">Notice: Key terms in the Terms and Conditions</p>

                <p>We know this is a lot to read, so we've picked out a few key terms which should be specifically brought to your attention. We do encourage you to read all documents to ensure that you are fully informed on the terms of our offering.</p>

                <p><span class="fw-bold">Our Agreement with you:</span> These terms and conditions, together with associated policies, form our contract with you for UCT Online High School.</p>

                <p><span class="fw-bold">Withdrawals:</span> Three calendar months (approximately one term) notice is required for withdrawal from the school.</p>

                <p><span class="fw-bold">Payment of Fees:</span> By enrolling, you agree to payment of fees on time. Please ensure you are well acquainted with these fees and available payment methods. In certain circumstances, access to the learning platform may be suspended due to non-payment of fees.</p>

                <label for="accept_terms" class="form-check-label mb-2">
                    <input name="accept_terms" id="accept_terms" type="checkbox"  class="form-check-input" required>
                    I have read and accepted the
                    <a href="https://www.uctonlinehighschool.com/policies" target="_blank" class="text-light-blue"> UCT Online High School Terms &#38; Conditions</a>

                    <span class="text-danger error-message">Please accept UCT Online High School Terms & Conditions</span>
                </label>

                <label for="admissions_policy" class="form-check-label mb-4">
                    <input name="admissions_policy" id="admissions_policy" type="checkbox"  class="form-check-input" required>
                    I have read and accepted the <a href="https://www.uctonlinehighschool.com/policies" target="_blank" class="text-light-blue"> UCT Online High School Handbooks and Policies</a>
                    <span class="text-danger error-message">Please accept UCT Online High School Handbooks and Policies</span>
                </label>

                <h5 class="mb-3 mt-5 text-pastel-blue text-center">Placement Fee</h5>
                <p>Please choose your payment mechanism for the placement fee. <span class="fw-bold">Note that tuition payments will require your credit or debit card details.</span></p>
            </div>

            <button
                class="new-btn new-btn-dark new-btn-xl payment-button text-light m-auto d-block my-3"
                :class="{'pending-verification': isPendingVerification}"
                type="submit"
                :disabled="isLoading || isPendingVerification"
            >
                <template v-if="isLoading">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </template>
                <template v-else-if="isPendingVerification">
                    Payment received! Verifying transaction…
                </template>
                <template v-else>
                    <div>
                        {{ `Pay R${ amount / 100 } Securely via Paystack` }}
                    </div>
                </template>
            </button>

            <p v-if="isPendingVerification" class="alert alert-warning" role="alert">
                We are verifying your transaction, <span class="fw-bold">please don't close this page</span>. You will be automatically directed to the next step of the application process once verification is complete
            </p>

            <p class="mb-2 mt-2 text-secondary">
                <i class="bi bi-info-circle"></i>&nbsp;To finalise payment, you may be guided to your bank's secure processor to authenticate the transaction.
            </p>

            <p v-if="isPlacementFee">
                Unsure about how to pay? Watch our payment video tutorial by <a target="_blank" @click.prevent="toggleEmbed" href="#">clicking here</a>
            </p>
            <transition name="slide">
                <div class="embed-responsive embed-responsive-16by9" v-show="showEmbed">
                    <iframe class="embed-responsive-item help-video" src="https://www.youtube.com/embed/6FOn01c2kGc" allowfullscreen></iframe>
                </div>
            </transition>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import scroll_to from '../mixins/scroll_to';
import error_handling from '../mixins/error_handling';

export default {
    name: 'PaymentForm',
    mixins: [
        scroll_to,
        error_handling,
    ],
    props: {
        feeType: {
            type: String,
            required: true,
        },
        currency: {
            type: String,
            default: 'ZAR',
        },
        firstName: {
            type: String,
            required: true,
        },
        lastName: {
            type: String,
            required: true,
        },
        amount: {
            type: Number,
            required: true,
        },
        customerEmail: {
            type: String,
            required: true,
        },
        paystackKey: {
            type: String,
            required: true,
        },
        verificationUrl: {
            type: String,
            required: true,
        },
        applicationId: {
            type: String,
            required: true,
        },
        discountsJson: {
            type: String,
            required: true,
        },
        appliedCurriculum: {
            type: String,
            required: false,
        },
        originalAmount: {
            type: Number,
            default: null
        },
        metadata: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            selectedDiscount: null,
            isPendingVerification: false,
            showEmbed: false,

            // Mixin Settings
            errorHandlingSettings: {
                selector: '.error-message',
            }
        };
    },
    mounted() {
        this.registerInputListeners();
    },
    computed: {
        discounts() {
            return JSON.parse(this.discountsJson);
        },
        shouldShowDiscounts() {
            // Only show discounts for placement-fee.
            return this.isPlacementFee && Object.keys(this.discounts).length > 0;
        },
        isPlacementFee() {
            return this.feeType === 'placement-fee';
        }
    },
    methods: {
        toggleEmbed() {
            this.showEmbed = !this.showEmbed;
        },
        handleFormSubmission() {
            this.submitForm();
        },
        async submitForm() {
            const paystack = new PaystackPop();
            await paystack.checkout({
                key: this.paystackKey,
                email: this.customerEmail,
                amount: this.amount,
                currency: 'ZAR',
                onCancel: () => {
                    console.error('User closed popup')
                },
                onSuccess: (transaction) => {
                    let verifyUrl = this.verificationUrl.replace('transactionReference', transaction.reference);
                    if (this.selectedDiscount && this.selectedDiscount.toLowerCase() != 'none') {
                        let tempUrlObj = new URL(verifyUrl);
                        tempUrlObj.searchParams.set('discount', this.selectedDiscount);
                        verifyUrl = tempUrlObj.toString();
                    }
                    this.triggerGaEvent(this.amount / 100);
                    this.isPendingVerification = true;
                    window.unsaved = false;
                    // Redirect user to verification endpoint.
                    window.location = verifyUrl;
                },
                metadata: this.metadata,

                // Keep these set. They override some default to force the transaction no to be split.
                tlsFallback: '',
                split: {},
            });
        },
        onChangeClearErrorFor(errorField) {
            this[errorField] = null;
        },
        triggerGaEvent(amountPaid) {
            window.dataLayer = window.dataLayer || [];
            let payload = {
                'event': 'placementFeePaid',
                'amount': `${amountPaid}`,
                'appliedCurriculum': this.appliedCurriculum,
                'userid': window.valenture.userId,
            };

            if (this.selectedDiscount && this.selectedDiscount.toLowerCase() != 'none') {
                payload.promotionalOffer = this.discounts[this.selectedDiscount];
            }

            window.dataLayer.push(payload);
        },
        registerInputListeners() {
            let inputs = Array.from(document.querySelectorAll('input'));

            inputs.forEach(input => {
                input.addEventListener('invalid', event => {
                    input.classList.add('invalid');

                    setTimeout(() => {
                        this.scrollIntoView(this.firstError());
                    }, 10);
                }, false);

                input.addEventListener('input', event => {
                    input.classList.remove('invalid');
                }, false);

                input.addEventListener('blur', event => {
                    input.checkValidity();
                })
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../sass/abstract/colours.scss';

.payment-button.pending-verification[disabled] {
    background-color: $pastel-green !important;
    text-transform: none;
}

.help-video {
    width: 100%;
    height: 50vh;
}

.slide-enter-active, .slide-leave-active {
    transition: all .5s ease;
}
.slide-enter, .slide-leave-to {
    transform: translateY(100%);
}
.slide-enter-to, .slide-leave {
    transform: translateY(0);
}

.error-message {
    font-size: 0.8em;
}

input ~ .error-message {
    display: none;
}

input.invalid ~ .error-message {
    display: block;
}
</style>