import './scripts';
import './bootstrap';
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VueFileAgent from 'vue-file-agent';
/***** DO NOT REMOVE THIS STYLING *****/
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import { router } from './router'
import * as Sentry from "@sentry/vue";
import axios from 'axios';

import GuardianLoginForm from './components/GuardianLoginForm.vue';
import ProfileEditForm from './components/ProfileEditForm.vue';
import AdditionalDataForm from './components/AdditionalDataForm.vue';
import LearnerPortfolioUploader from './components/LearnerPortfolioUploader.vue';
import GuardianSignUpForm from './components/GuardianSignUpForm.vue';
import GuardianSetPasswordForm from './components/GuardianSetPasswordForm.vue';
import GuardianLearnerAppStepOneForm from './components/GuardianLearnerAppStepOneForm.vue';
import GuardianLearnerAppStepTwoForm from './components/GuardianLearnerAppStepTwoForm.vue';
import GuardianLearnerDocumentVerification from './components/GuardianLearnerDocumentVerification.vue';
import GuardianLearnerAppStepTwoBarriersToLearningForm from './components/GuardianLearnerAppStepTwoBarriersToLearningForm.vue';
import PaymentForm from './components/PaymentForm.vue';
import ResetPasswordForm from './components/ResetPasswordForm.vue';
import ForgotPasswordForm from './components/ForgotPasswordForm.vue';
import ViPasswordInput from './components/ViPasswordInput.vue';
import ViErrorFlash from './components/ViErrorFlash.vue';
import ViButtonSpinner from './components/ViButtonSpinner.vue';
import GuardianLearnerAppCapsSubjectSelection from './components/GuardianLearnerAppCapsSubjectSelection.vue';
import GuardianLearnerAppAdultMatricSubjectSelection from './components/GuardianLearnerAppAdultMatricSubjectSelection.vue';
import FloatingIconsBackground from './components/FloatingIconsBackground.vue';
import ApplicationStepsProgress from './components/ApplicationStepsProgress.vue';
import SuccessAlert from './components/SuccessAlert.vue';
import PassportStylePhotoStep from './components/PassportStylePhotoStep.vue';
import FinalReportSubmission from './components/FinalReportSubmission.vue';
import ViReEnrolmentSurvey from './components/ViReEnrolmentSurvey.vue';
import AdultMatricBulkUpload from './components/AdultMatricBulkUpload.vue';
import ViProgressBar from './components/ViProgressBar.vue';
import ViTableCell from './components/vi-table/ViTableCell.vue';
import ViTableRow from './components/vi-table/ViTableRow.vue';
import ViTable from './components/vi-table/ViTable.vue';
import ViLooker from './components/vi-table/ViLooker.vue';
import ViFileSelector from './components/ViFileSelector.vue';
import DummyUploadStep from './components/DummyUploadStep.vue';
import DocumentVerificationUpload from './components/DocumentVerificationUpload.vue';
import LearnerInformationForm from './components/LearnerInformationForm.vue';
import ViAddressFieldGroup from './components/ViAddressFieldGroup.vue';
import EmailCodeVerification from './components/EmailCodeVerification.vue';
import ViSecurityCodeInput from './components/ViSecurityCodeInput.vue';
import TuitionStepForm from './components/payments/TuitionStepForm.vue';
import UserDetails from './components/payments/UserDetails.vue';
import PaymentMethods from './components/payments/PaymentMethods.vue';
import PaymentSummary from './components/payments/PaymentSummary.vue';
import Student from './components/dashboard/Student.vue';
import PacingData from './components/dashboard/PacingData.vue';
import DashboardActionCard from './components/dashboard/DashboardActionCard.vue';
import LookerModal from './components/vi-table/LookerModal.vue';
import Reports from './components/dashboard/Reports.vue';
import Teachers from './components/dashboard/Teachers.vue';
import SupportCoach from './components/dashboard/SupportCoach.vue';
import SupportCoachBooking from './components/dashboard/SupportCoachBooking.vue';
import UpcomingExamsModal from './components/dashboard/UpcomingExamsModal.vue';
import ApplicationActionCard from './components/dashboard/ApplicationActionCard.vue';
import ProgressionApplication from './components/dashboard/ProgressionApplication.vue';
import ViSelectableCard from './components/ViSelectableCard.vue';
import GuardianAccountSection from './components/dashboard/GuardianAccountSection.vue';
import AccountInput from './components/dashboard/AccountInput.vue';
import GuardianBasicInfoSection from './components/dashboard/GuardianBasicInfoSection.vue';
import PhoneNumberAccountInput from './components/dashboard/PhoneNumberAccountInput.vue';
import PhoneNumberInput from './components/dashboard/PhoneNumberInput.vue';
import GuardianLearnerBasicInfoSection from './components/dashboard/GuardianLearnerBasicInfoSection.vue';
import IdOrPassportInput from './components/dashboard/IdOrPassportInput.vue';
import AccountSelect from './components/dashboard/AccountSelect.vue';
import BillingDetailsSection from './components/dashboard/BillingDetailsSection.vue';
import AccountRadio from './components/dashboard/AccountRadio.vue';
import InvoiceHistorySection from './components/dashboard/InvoiceHistorySection.vue';
import InvoiceTable from './components/dashboard/InvoiceTable.vue';
import SubscriptionInvoiceStatus from './components/dashboard/SubscriptionInvoiceStatus.vue';
import EmergencyContactForm from './components/dashboard/EmergencyContactForm.vue';
import SubjectSelectionNotice from './components/subject-selection/SubjectSelectionNotice.vue';
import ViQueryButton from './components/dashboard/ViQueryButton.vue';
import ViGradingQueryReasons from './components/dashboard/ViGradingQueryReasons.vue';
import ViGradingQueryAdditionalInfo from './components/dashboard/ViGradingQueryAdditionalInfo.vue';
import GradingQueryNoticeModal from './components/dashboard/GradingQueryNoticeModal.vue';
import ViStudentList from './components/dashboard/ViStudentList.vue';
import CardDetailsSection from './components/dashboard/CardDetailsSection.vue';
import ViSubjectSelection from './components/vi-subject-selection/ViSubjectSelection.vue';
import ViSubjectGroup from './components/vi-subject-selection/ViSubjectGroup.vue';
import ViSubject from './components/vi-subject-selection/ViSubject.vue';
import ViAccountInput from './components/account/ViAccountInput.vue';
import ViAddressGroup from './components/account/ViAddressGroup.vue';
import ViAccountAddress from './components/account/ViAccountAddress.vue';
import VirtualClubsSuccessModal from './components/VirtualClubsSuccessModal.vue';
import VirtualClubSection from './components/VirtualClubSection.vue';
import DefferedTestApplicationForm from './components/DefferedTestApplicationForm.vue';
import ReEnrolmentSurvey from './components/dashboard/ReEnrolmentSurvey.vue';
import ReEnrolmentStudentResponse from './components/dashboard/ReEnrolmentStudentResponse.vue';
import ReEnrolmentUnsavedChanges from './components/dashboard/ReEnrolmentUnsavedChanges.vue';
import ReEnrolmentSuccess from './components/dashboard/ReEnrolmentSuccess.vue';
import Applications from './components/dashboard/Applications.vue';
import ApplicationDetails from './components/dashboard/ApplicationDetails.vue';
import ViUploadableCard from './components/ViUploadableCard.vue';
import ViPasswordStrengthMeter from './components/ViPasswordStrengthMeter.vue';
import ApplyForLLS from './components/dashboard/ApplyForLLS.vue';
import TermlyProgress from './components/dashboard/TermlyProgress.vue';
import ProgressSubjects from './components/dashboard/ProgressSubjects.vue';
import ModuleCount from './components/dashboard/ModuleCount.vue';
import TermlyProgressTable from './components/dashboard/TermlyProgressTable.vue';
import ProgressModules from './components/dashboard/ProgressModules.vue';
import ProgressTableRow from './components/dashboard/ProgressTableRow.vue';
import ProgressTableCell from './components/dashboard/ProgressTableCell.vue';
import ScholarshipLayout from './components/scholarship-application/elements/ScholarshipLayout.vue';
import ScholarshipApplication from './components/scholarship-application/ScholarshipApplication.vue';
import ScholarshipProgressBar from './components/scholarship-application/elements/ScholarshipProgressBar.vue';
import ScholarshipActionButtons from './components/scholarship-application/elements/ScholarshipActionButtons.vue';
import ScholarshipCheckbox from './components/scholarship-application/elements/ScholarshipCheckbox.vue';
import ScholarshipTableRow from './components/scholarship-application/elements/ScholarshipTableRow.vue';
import ViAlert from './components/scholarship-application/elements/ViAlert.vue';
import ViSchoolsDropdown from './components/scholarship-application/elements/ViSchoolsDropdown.vue';
import LoadingData from './components/scholarship-application/elements/LoadingData.vue';
import LoadingOverlay from './components/scholarship-application/elements/LoadingOverlay.vue';
import LlsWelcomeModal from './components/scholarship-application/elements/LlsWelcomeModal.vue';
import IdNumberPopUp from './components/account/IdNumberPopUp.vue';
import LinkAccount from './components/guardian/LinkAccount.vue';
import AssessmentResubmit from './components/assessment-resubmission/AssessmentResubmit.vue';
import AssessmentResubmitInit from './components/assessment-resubmission/AssessmentResubmitInit.vue';
import TestEmailField from './components/assessment-resubmission/TestEmailField.vue';
import ViWeeklyAttendance from './components/dashboard/ViWeeklyAttendance.vue';
import TimeTable from './components/time-table/TimeTable.vue';
import LiveSessionTimeTableModal from './components/time-table/LiveSessionTimeTableModal.vue';
import ReviewG12PreRegistrationForm from './components/guardian/ReviewG12PreRegistrationForm.vue';
import MatricExamEntrySchedule from './components/guardian/MatricExamEntrySchedule.vue';

import StepOneController from './components/steps/StepOneController.vue';
import AdultMatricLearnerInfo from './components/adult-matric/LearnerInfo.vue';
import AdultMatricSubjectSelection from './components/adult-matric/SubjectSelection.vue';
import Tuition from './components/adult-matric/Tuition.vue';
import ErrorMessage from './components/adult-matric/ErrorMessage.vue';

import CountdownTimer from './components/dashboard/CountdownTimer.vue';
import Withdrawal from './components/withdrawal/Withdrawal.vue';
import WithdrawalSection from './components/withdrawal/WithdrawalSection.vue';
import ManageSection from './components/account/ManageSection.vue';
import ManageAccount from './components/account/ManageAccount.vue';
import ChangeSubjectsOrBundle from './components/account/ChangeSubjectsOrBundle.vue';

import ModalBase from './components/subject-change-request/ModalBase.vue';
import ChangeRequest from './components/subject-change-request/ChangeRequest.vue';
import DeadlinePassed from './components/subject-change-request/DeadlinePassed.vue';
import Success from './components/subject-change-request/Success.vue';

import SubjectChangeRequest from './components/account/SubjectChangeRequest.vue';

Vue.use(Vuetify);
Vue.use(VueFileAgent);
window.Vue = Vue;

Vue.component('profile-edit-form', ProfileEditForm);
Vue.component('additional-data-form', AdditionalDataForm);
Vue.component('learner-portfolio-uploader', LearnerPortfolioUploader);
Vue.component('guardian-login-form', GuardianLoginForm);
Vue.component('guardian-sign-up-form', GuardianSignUpForm);
Vue.component('guardian-set-password-form', GuardianSetPasswordForm);
Vue.component('guardian-learner-app-step-one-form', GuardianLearnerAppStepOneForm);
Vue.component('guardian-learner-app-step-two-form', GuardianLearnerAppStepTwoForm);
Vue.component('guardian-learner-document-verification', GuardianLearnerDocumentVerification);
Vue.component('guardian-learner-app-step-two-barriers-to-learning-form', GuardianLearnerAppStepTwoBarriersToLearningForm);
Vue.component('payment-form', PaymentForm);
Vue.component('reset-password-form', ResetPasswordForm);
Vue.component('forgot-password-form', ForgotPasswordForm);
Vue.component('vi-password-input', ViPasswordInput);
Vue.component('vi-error-flash', ViErrorFlash);
Vue.component('vi-button-spinner', ViButtonSpinner);
Vue.component('guardian-learner-app-caps-subject-selection', GuardianLearnerAppCapsSubjectSelection);
Vue.component('guardian-learner-app-adult-matric-subject-selection', GuardianLearnerAppAdultMatricSubjectSelection);
Vue.component('floating-icons-background', FloatingIconsBackground);
Vue.component('application-steps-progress', ApplicationStepsProgress);
Vue.component('success-alert', SuccessAlert);
Vue.component('passport-style-photo-step', PassportStylePhotoStep);
Vue.component('final-report-submission-step', FinalReportSubmission);
Vue.component('vi-re-enrolment-survey', ViReEnrolmentSurvey);
Vue.component('adult-matric-bulk-upload', AdultMatricBulkUpload);
Vue.component('vi-progress-bar', ViProgressBar);

Vue.component('vi-table-cell', ViTableCell);
Vue.component('vi-table-row', ViTableRow);
Vue.component('vi-table', ViTable);
Vue.component('vi-looker', ViLooker);
Vue.component('vi-file-selector', ViFileSelector);
Vue.component('dummy-upload-step', DummyUploadStep);

Vue.component('document-verification-upload', DocumentVerificationUpload);

Vue.component('learner-information-form', LearnerInformationForm);

Vue.component('vi-address-field-group', ViAddressFieldGroup);

Vue.component('email-code-verification', EmailCodeVerification);
Vue.component('vi-security-code-input', ViSecurityCodeInput);

// Payments
Vue.component('tuition-step-form', TuitionStepForm);
Vue.component('user-details', UserDetails);
Vue.component('payment-methods', PaymentMethods);
Vue.component('payment-summary', PaymentSummary);

Vue.component('student', Student);
Vue.component('pacing-data', PacingData);
Vue.component('dashboard-action-card', DashboardActionCard);
Vue.component('looker-modal', LookerModal);
Vue.component('reports', Reports);
Vue.component('teachers', Teachers);
Vue.component('support-coach', SupportCoach);
Vue.component('support-coach-booking', SupportCoachBooking);
Vue.component('upcoming-exams-modal', UpcomingExamsModal);
Vue.component('application-action-card', ApplicationActionCard);
Vue.component('progression-application', ProgressionApplication);
Vue.component('vi-selectable-card', ViSelectableCard);
Vue.component('guardian-account-section', GuardianAccountSection);
Vue.component('account-input', AccountInput);
Vue.component('guardian-basic-info-section', GuardianBasicInfoSection);
Vue.component('phone-number-account-input', PhoneNumberAccountInput);
Vue.component('phone-number-input', PhoneNumberInput);
Vue.component('guardian-learner-basic-info-section', GuardianLearnerBasicInfoSection);
Vue.component('id-or-passport-input', IdOrPassportInput);
Vue.component('account-select', AccountSelect);
Vue.component('billing-details-section', BillingDetailsSection);
Vue.component('account-radio', AccountRadio);
Vue.component('billing-details-section', BillingDetailsSection);
Vue.component('invoice-history-section', InvoiceHistorySection);
Vue.component('invoice-table', InvoiceTable);
Vue.component('subscription-invoice-status', SubscriptionInvoiceStatus);
Vue.component('emergency-contact-form', EmergencyContactForm);
Vue.component('subject-selection-notice', SubjectSelectionNotice);
Vue.component('vi-query-button', ViQueryButton);
Vue.component('vi-grading-query-reasons', ViGradingQueryReasons);
Vue.component('vi-grading-query-additional-info', ViGradingQueryAdditionalInfo);
Vue.component('grading-query-notice-modal', GradingQueryNoticeModal);
Vue.component('vi-student-list', ViStudentList);

Vue.component('card-details-section', CardDetailsSection);
Vue.component('vi-subject-selection', ViSubjectSelection);
Vue.component('vi-subject-group', ViSubjectGroup);
Vue.component('vi-subject', ViSubject);

Vue.component('vi-account-input', ViAccountInput);
Vue.component('vi-address-group', ViAddressGroup);
Vue.component('vi-account-address', ViAccountAddress);

Vue.component('virtual-clubs-success-modal', VirtualClubsSuccessModal);
Vue.component('virtual-club-section', VirtualClubSection);

Vue.component('deffered-test-application-form', DefferedTestApplicationForm);
Vue.component('re-enrolment-survey', ReEnrolmentSurvey);
Vue.component('re-enrolment-student-response', ReEnrolmentStudentResponse);
Vue.component('re-enrolment-unsaved-changes', ReEnrolmentUnsavedChanges);
Vue.component('re-enrolment-success', ReEnrolmentSuccess);


Vue.component('vi-applications', Applications);
Vue.component('vi-application-details', ApplicationDetails);

Vue.component('vi-uploadable-card', ViUploadableCard);
Vue.component('vi-password-strength-meter', ViPasswordStrengthMeter);
Vue.component('apply-for-lls', ApplyForLLS);

Vue.component('termly-progress', TermlyProgress);
Vue.component('progress-subjects', ProgressSubjects);
Vue.component('module-count', ModuleCount);
Vue.component('termly-progress-table', TermlyProgressTable);
Vue.component('progress-modules', ProgressModules);
Vue.component('progress-table-row', ProgressTableRow);
Vue.component('progress-table-cell', ProgressTableCell);

/**
 * Sholarship Application Process
 */

Vue.component('scholarship-layout', ScholarshipLayout);
Vue.component('scholarship-application', ScholarshipApplication);
Vue.component('scholarship-progress-bar', ScholarshipProgressBar);
Vue.component('scholarship-action-buttons', ScholarshipActionButtons);
Vue.component('scholarship-checkbox', ScholarshipCheckbox);
Vue.component('scholarship-table-row', ScholarshipTableRow);
Vue.component('vi-alert', ViAlert);
Vue.component('vi-schools-dropdown', ViSchoolsDropdown);
Vue.component('loading-data', LoadingData);
Vue.component('loading-overlay', LoadingOverlay);
Vue.component('lls-welcome-modal', LlsWelcomeModal);
Vue.component('id-number-pop-up', IdNumberPopUp);

Vue.component('link-account', LinkAccount);

Vue.component('assessment-resubmit', AssessmentResubmit);
Vue.component('assessment-resubmit-init', AssessmentResubmitInit);
Vue.component('test-email-field', TestEmailField);
Vue.component('vi-weekly-attendance', ViWeeklyAttendance);

Vue.component('time-table', TimeTable);
Vue.component('live-session-time-table-modal', LiveSessionTimeTableModal);

Vue.component('review-g12-pre-registration-form', ReviewG12PreRegistrationForm);
Vue.component('step-one-controller', StepOneController);
Vue.component('adult-matric-learner-info', AdultMatricLearnerInfo);
Vue.component('adult-matric-subject-selection', AdultMatricSubjectSelection);
Vue.component('error-message', ErrorMessage);
Vue.component('adult-matric-tuition', Tuition);

Vue.component('countdown-timer', CountdownTimer);
Vue.component('vi-withdrawal', Withdrawal);
Vue.component('vi-withdrawal-section', WithdrawalSection);
Vue.component('vi-manage-section', ManageSection);
Vue.component('vi-manage-account', ManageAccount);
Vue.component('vi-change-subjects-or-bundle', ChangeSubjectsOrBundle);

Vue.component('matric-exam-entry-schedule', MatricExamEntrySchedule);

Vue.component('vi-subject-change-requests-modal-base', ModalBase);
Vue.component('vi-subject-change-requests-change-request', ChangeRequest);
Vue.component('vi-subject-change-requests-deadline-passed', DeadlinePassed);
Vue.component('vi-subject-change-requests-success', Success);

Vue.component('vi-subject-change-request', SubjectChangeRequest);

Vue.prototype.$setEndPoints = setEndPoints;

function setEndPoints(endPoints) {
    Vue.prototype.$endPointsConfig = endPoints;
}

// set interceptor for all 401 responses to redirect to login page
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        if (window.location.hostname === new URL(error.response.config.url).hostname) {
            window.location.href = '/uct?session_expired=true';
        }
    }
    return Promise.reject(error);
});


Sentry.init({
    Vue,
    dsn: window.sentry.dsn,
    environment: window.sentry.environment,
    integrations: [
    ],
});

const app = new Vue({
    router,
    vuetify: new Vuetify(),
    el: '#app',
});
