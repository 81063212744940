<template>
    <div>
        <h3 class="mb-4 text-dark">Tuition Fee Setup</h3>

        <form
            @submit.prevent="submitHandler"
            id="tuition-setup-form-component"
            class="text-start guardian-loging-form-container mx-auto"
        >
            <user-details
                title="Account Holder Details"
                :first-name="guardian.FirstName"
                :last-name="guardian.LastName"
            ></user-details>

            <user-details
                title="Learner's Details"
                :first-name="student.FirstName"
                :last-name="student.LastName"
                :application-curriculum-block="true"
                :app-curriculum="qualificationLevel.Curriculum__c"
            ></user-details>

            <payment-methods
                v-if="card"
                :is-active="loading || readOnly"
                :card="card"
            ></payment-methods>

            <payment-methods
                v-else
                :is-active="loading || readOnly"
            ></payment-methods>

            <payment-summary
                :monthly-item-price="subscriptionOptions.subscription.monthly"
                :months18-item-price="subscriptionOptions.subscription.months18"
                :addon-charges="subscriptionOptions.extraSubjectCharge"
                :curriculum="qualificationLevel.Curriculum__c"
                v-model="selectedPlan"
                :is-active="loading || readOnly"
                :chosen-plan="chosenPlan"
                :start-date="startDate"
                :tuition-fee-coupons="subscriptionOptions.tuitionFeeCoupons"
                :starting-term="startingTerm"
            ></payment-summary>

            <template>
                <p v-if="verifying && !isMobile" class="text-center alert alert-warning">Verifying card, please don't close this page.</p>
                <p v-else-if="!isMobile" class="text-center alert alert-warning">Please click {{ card ? '"Edit Card and Submit Application"' : '"Add Card and Submit Application"' }} and enter your card details. Once this popup is closed we will verify your card and confirm your application. If you experience errors on this page, please refresh the page. If the problem persists, please reach out to <a href="mailto:support@valentureinstitute.com">support@valentureinstitute.com</a>.</p>
            </template>

            <p v-if="redirecting" class="text-center alert alert-warning">We're redirecting you to the next step. <span class="fw-bold">Please don't close the window.</span></p>

            <div v-if="hasError" class="alert alert-danger">
                <p v-if="errorMsg" v-html="errorMsg" class="m-0"></p>

                <template v-else>
                    Something went wrong. Please retry or <a href="mailto:techsupport@uctohs.valentureonline.com">contact us for help</a>.
                </template>
            </div>

            <div class="w-100">
                <!-- Edit Card and Submit Application -->
                <vi-button-spinner
                    v-if="card"
                    data-action="editPaymentMethod"
                    :disabled="loading || redirecting || !card || verifying"
                    :buttonText="'Edit Card and Submit Application'"
                    :loading="(loading || verifying)"
                ><template  v-if="verifying && !pendingVerification" v-slot:loader>Verifying your card details, please wait...</template></vi-button-spinner>

                <!-- Add Card and Submit Application -->
                <vi-button-spinner
                    v-if="!card"
                    data-action="addPaymentMethod"
                    :disabled="loading || redirecting || verifying"
                    :buttonText="'Add Card and Submit Application'"
                    :loading="(loading || verifying)"
                ><template  v-if="verifying" v-slot:loader>Verifying your card details, please wait...</template></vi-button-spinner>

                <!-- Submit Application -->
                <vi-button-spinner
                    v-if="card"
                    data-action="submit"
                    :disabled="loading || redirecting || !card || verifying"
                    :buttonText="'Submit Application'"
                    :loading="(loading || verifying)"
                >
                    <template  v-if="verifying" v-slot:loader>Verifying your card details, please wait...</template>
                </vi-button-spinner>
            </div>
        </form>
    </div>
</template>

<script>
import UserDetails from './UserDetails.vue';
import PaymentMethods from './PaymentMethods.vue';
import PaymentSummary from './PaymentSummary.vue';
import axios from 'axios';

export default {
    name: "TuitionStepForm",
    data: () => ({
        selectedPlan: null,
        loading: false,
        hasError: false,
        errorMsg: null,
        chargeBee: null,

        redirecting: false,
        verifying: false,
        pendingVerification: false,
    }),
    components: {
        UserDetails,
        PaymentMethods,
        PaymentSummary
    },
    props: {
        guardian: {
            type: Object,
            required: true,
        },
        student: {
            type: Object,
            required: true,
        },
        qualificationLevel: {
            type: Object,
            required: true,
        },
        subscriptionOptions: {
            type: Object,
            required: true,
        },
        submitUrl: {
            type: String,
            required: true,
        },
        backUrl: {
            type: String,
            required: true,
        },
        verifyUrl: {
            type: String,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        chosenPlan: {
            type: String,
            default: null,
        },
        startDate: {
            type: String,
            required: true,
        },
        programEnrollmentId: {
            type: String,
            required: true,
        },
        startingTerm: {
            type: String,
            required: true,
        },
        chargebeeKey: {
            type: String,
            required: true,
        },
        chargebeeSite: {
            type: String,
            required: true,
        },
        card: {
            type: Object,
            default: null,
        },
        editPaymentMethodUrl: {
            type: String,
            required: true,
        },
        addPaymentMethodUrl: {
            type: String,
            required: true,
        },
        verifyUrl: {
            type: String,
            required: true,
        },
        dashboardUrl: {
            type: String,
            required: true,
        }
    },
    mounted() {
        if (this.chosenPlan) {
            this.selectedPlan = this.chosenPlan;
        }

        this.chargeBee = Chargebee.init({
            site: this.chargebeeSite,
            publishableKey: this.chargebeeKey,
            iframeOnly: true,
        });
    },
    methods: {
        submitHandler(event) {
            let submitButton = event.submitter;
            let action = submitButton.dataset['action'];

            switch (action) {
                case 'submit':
                    this.submitAndEdit(event.target, this.submitUrl);
                    break;
                case 'editPaymentMethod':
                    this.submitAndEdit(event.target, this.editPaymentMethodUrl);
                    break;
                case 'addPaymentMethod':
                    this.submitAndEdit(event.target, this.addPaymentMethodUrl);
                    break;
                default:
                    throw new Error('Unsupported Action');
                    break;
            }
        },
        submitAndEdit(form, url) {
            this.loading = true;
            this.hasError = false;
            this.errorMsg = null;

            let data = {};

            axios.post(url, data)
                .then(res => {
                    let handleUnload = (event) => {
                        var confirmationMessage = 'Are you sure?';

                        if (this.redirecting) {
                            confirmationMessage = 'Please wait while we redirect you to the next step.';
                        } else {
                            return;
                        }

                        (event || window.event).returnValue = confirmationMessage; //Gecko + IE
                        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
                    };

                    this.hasError = false;
                    this.errorMsg = null;

                    // If not on mobile device where pop up breaks, open popup.
                    if (!this.isMobile && res.data.hasOwnProperty('hostedPage') && res.data.hasOwnProperty('successRedirect')) {
                        window.addEventListener('beforeunload', handleUnload);

                        let {
                            hostedPage,
                            successRedirect,
                        } = res.data;

                        this.chargeBee.openCheckout({
                            hostedPage: async () => {
                                return hostedPage;
                            },
                            close: async () => {
                                this.loading = true;
                                this.verifying = true;

                                try {
                                    // Verify that card has been added.
                                    let response = await axios.get(this.verifyUrl);
                                    window.removeEventListener('beforeunload', handleUnload);

                                    if (response.data.hasOwnProperty('isPending') && response.data.isPending) {
                                        this.pendingVerification = response.data.isPending;
                                    } else {
                                        window.location.href = successRedirect;
                                    }

                                } catch (error) {
                                    this.loading = false;
                                    this.hasError = true;
                                    this.verifying = false;
                                    this.errorMsg = error.response.data.error;

                                    this.$nextTick(() => this.scrollIntoView(this.$refs.errorAlert));

                                    return;
                                }
                            },
                        });
                    } else {
                        this.loading = false;
                        this.redirecting = true;

                        window.location.href = res.data.redirect;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    this.hasError = true;
                    this.redirecting = false;

                    if (err.response.data.hasOwnProperty('error')) {
                        this.errorMsg = err.response.data.error;
                    }
                });
        },
    },
    computed: {
        isMobile() {
            return window.innerWidth < 480;
        }
    }
}
</script>

<style lang="scss" scoped>

@import '../../../sass/abstract/colours.scss';
@import '../../../sass/utilities/rem-calc.scss';

.consent-label {
    color: $black;
    font-size: rem-calc(14);
}

.new-btn {
    max-width: none;
}
</style>